<template>
  <div v-if="this.isSPMUser()" class="chat__input">
    <div class="chat__input__content">
      <textarea v-model="textAreaText" @keypress.enter="enterPressed" ref="textArea" placeholder=" " onInput="this.parentNode.dataset.replicatedValue = this.value"></textarea>
      <div v-on:click="sendTextClicked" class="chat__input__send"></div>
      <div v-on:click="openInputOptions" v-bind:class="showInputOptions ? 'chat__input__more chat__input__more--close' : 'chat__input__more'"></div>
    </div>
    <ChatAnswer v-if="answer" @answerClosed="answerClosed" :username="answerUser" :text="answerText" :chatItem="answerItem" closeable></ChatAnswer>
    <div class="chat__input__background"></div>
    <div v-bind:class="showInputOptions ? 'chat__input__options chat__input__options--visible' : 'chat__input__options'">
      <input id="imagefileInput" type="file" multiple :name="uploadFieldName"
             class="input-file" style="display: none" accept="image/png, image/jpeg"
             @change="filesChange($event.target.name, $event.target.files);">
      <input id="fileInput" type="file" multiple :name="uploadFieldName"
             class="input-file" style="display: none" accept=".doc,.docx,.xlsx,.xls,.ppt,.pptx,.txt,.pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/pdf,application/vnd.ms-excel,application/msword,application/vnd.ms-powerpoint,text/plain"
             @change="filesChange($event.target.name, $event.target.files);">
      <div v-on:click="addFileClicked('imagefileInput')" class="chat__input__options__item">
        Foto
        <img src="@/assets/chat/ic_20_camera.svg" alt="">
      </div>
      <!--
      <div class="chat__input__options__item">
        Sprachnachricht
        <img src="@/assets/chat/ic_20_voice.svg" alt="">
      </div>
      -->
      <div v-on:click="addFileClicked('fileInput')" class="chat__input__options__item">
        Datei
        <img src="@/assets/chat/ic_20_file.svg" alt="">
      </div>
    </div>
  </div>
  <div v-else class="chat__input">
    <div class="chat__input__content">
      <textarea v-model="textAreaText" @keypress.enter="enterPressed" ref="textArea" placeholder=""
                onInput="this.parentNode.dataset.replicatedValue = this.value"></textarea>
      <div v-on:click="sendTextClicked" class="chat__input__send"></div>
    </div>
    <ChatAnswer v-if="answer" @answerClosed="answerClosed" :username="answerUser" :text="answerText"
                closeable></ChatAnswer>
    <div class="chat__input__background"></div>
  </div>
</template>

<script>
import ChatAnswer from '../../components/chat/ChatAnswer'
import Vue from 'vue'

export default {
  name: 'ChatInput',
  components: { ChatAnswer },
  props: {
    answer: Boolean,
    answerUser: String,
    answerText: String,
    answerMessageId: String,
    answerItem: Object,
    uploadFieldName: 'chatfileupload'
  },
  data () {
    return {
      textAreaText: '',
      showInputOptions: false
    }
  },
  beforeCreate () {
  },
  methods: {
    filesChange (fieldName, fileList) {
      Vue.$log.debug('ChatInput.vue - filesChange')
      this.showInputOptions = false
      if (!fileList.length) {
        return
      }
      var filesOk = true
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i]
        const fileSizeinMB = file.size / 1024 / 1024
        if (fileSizeinMB > 100) {
          filesOk = false
          break
        }
      }
      if (filesOk) {
        Vue.$log.debug('ChatInput.vue - filesChange - emit')
        this.$emit('textEntered', { text: this.textAreaText, files: fileList, '_attachment_follows': true })
      }
    },
    addFileClicked (id) {
      Vue.$log.debug('ChatInput.vue - addFileClicked - id: ' + id)
      var input = document.getElementById(id)
      input.click()
    },
    enterPressed (e) {
      if (e.key === 'Enter') {
        e.stopPropagation()
        //this.sendTextClicked()
      }
    },
    sendTextClicked () {
      if (this.textAreaText.trim().length > 0) {
        if (this.answer && this.answerMessageId) {
          this.$emit('textEntered', { text: this.textAreaText, in_response_to: this.answerMessageId })
        } else {
          this.$emit('textEntered', { text: this.textAreaText })
        }
      }
      this.textAreaText = ''
      this.$refs.textArea.value = ''
      this.$refs.textArea.parentNode.dataset.replicatedValue = ''
    },
    answerClosed () {
      this.$emit('answerClosed', {})
    },
    openInputOptions () {
      this.showInputOptions = !this.showInputOptions
    },
  },
  computed: {}
}
</script>

<style lang="scss">

.chat__input {
  position: sticky;
  z-index: 2;
  bottom: 12px;
  display: flex;
  flex-direction: column;
  margin: 16px 12px -4px;
  box-sizing: border-box;
  border-radius: 24px;

  &__content {
    position: relative;
    display: flex;
    align-items: flex-end;
    flex: 1;
    overflow: hidden;
    border-radius: 24px;

    &:before {
      content: attr(data-replicated-value) " ";
      white-space: pre-wrap;
      visibility: hidden;
      font-size: 16px;
      padding: 14px 4px 14px 20px;
      width: 100%;
      word-break: break-word;
      max-height: 104px;
    }
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $colorWhite;
    border-radius: 24px;
    box-shadow: 0 0 0 1px rgba($colorGreyDark, .24);
    transition: all .2s ease-in-out;
  }

  textarea {
    position: absolute;
    z-index: 1;
    padding: 14px 4px 14px 20px;
    box-sizing: border-box;
    margin: 0;
    border: 0;
    outline: none;
    width: calc(100% - 44px);
    height: 100%;
    font-size: 16px;
    resize: none;
    line-height: 20px;
    background: none;
    color: $colorGreyDark;
    transition: all .2s ease-in-out;

    &:hover {
      ~ .chat__input__background {
        box-shadow: inset 0 0 0 1px rgba($colorGreyDark, .32);
      }
    }

    &:focus, &:active {
      outline: none;

      ~ .chat__input__background {
        box-shadow: inset 0 0 0 1px rgba($colorGreyDark, .32);
      }
    }

    &:not(:placeholder-shown) {
      ~ .chat__input__send {
        transform: scale(1);
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  &__send {
    position: relative;
    z-index: 3;
    display: flex;
    opacity: 0;
    transform: scale(.5);
    pointer-events: none;
    flex-shrink: 0;
    position: relative;
    padding: 4px 4px 4px 4px;
    width: 40px;
    height: 40px;
    box-sizing: content-box;
    border-radius: 50%;
    cursor: pointer;
    transition: all .2s ease-in-out;

    &:before {
      z-index: 2;
      position: absolute;
      content: url('../../assets/chat/ic_20_send.svg');
      padding: 10px;
      box-sizing: border-box;
      width: 40px;
      height: 40px;
      background: var(--color-primary);
      border-radius: 50%;
      box-shadow: 0 4px 8px 0 rgba(var(--color-primary-rgb), .16);
    }
  }

  &__more {
    position: absolute;
    width: 40px;
    height: 40px;
    margin: 4px;
    background: rgba(0, 0, 0, .08);
    z-index: 2;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    cursor: pointer;
    transition: all .2s ease-in-out;

    &:hover {
      background: rgba(0, 0, 0, .12);
    }

    &:after {
      position: absolute;
      content: '';
      width: 2px;
      height: 14px;
      top: calc(50% - 7px);
      left: calc(50% - 1px);
      background: $colorGreyDark;
      border-radius: 1px;
      transition: all .2s ease-in-out;
    }

    &:before {
      position: absolute;
      content: '';
      width: 14px;
      height: 2px;
      top: calc(50% - 1px);
      left: calc(50% - 7px);
      background: $colorGreyDark;
      border-radius: 1px;
      transition: all .2s ease-in-out;
    }

    &--close {
      background: var(--color-primary);

      &:hover {
        background: var(--color-primary);
      }

      &:after, &:before {
        background: $colorWhite;
        transform: rotate(45deg);
      }
    }
  }

  &__options {
    position: absolute;
    top: -12px;
    right: 0;
    border-radius: 8px;
    z-index: 4;
    min-width: 220px;
    background: $colorWhite;
    transform: translateY(calc(-100% + 8px));
    box-shadow: 0px 4px 16px rgba($colorGreyDark, .04), inset 0 0 0 1px rgba($colorGreyDark, .24);
    padding: 4px 0;
    pointer-events: none;
    opacity: 0;
    transition: all .2s ease-in-out;

    &--visible {
      transform: translateY(-100%);
      pointer-events: auto;
      opacity: 1;
      transition: all .2s ease-in-out;
    }

    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: $colorGreyDark;
      padding: 12px 16px;
      cursor: pointer;
      transition: all .2s ease-in-out;

      &:hover {
        background: rgba(#000000, .04);
      }

      &:not(:last-child) {
        box-shadow: inset 0 -1px 0 0 rgba($colorGreyDark, .08);
      }

      &__label {
        flex: 1;
      }

      > img {
        width: 20px;
        height: 20px;
        margin-left: 8px;
      }
    }
  }
}
</style>
