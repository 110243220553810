<template>
  <div :key="componentKey" class="bet" v-bind:style="{ paddingBottom: '64px' }">
  
    <keep-alive>
      <NavigationBar id="betNavigationBarId" ref="betNavigationBarRef">
        <template v-slot:center>
          <div class="navigation_title--headline">
            {{ $t('menu.bet') }}
          </div>
          <div v-if="!isBattle() && !isTeamsBetgame()" v-on:click="opemMatchdaySelect" class="navigation_title--subline">
            {{ matchdayTextTitle }}
          </div>
        </template>
        <template v-slot:right>
          <CalendarIcon v-if="!isBattle() && !isTeamsBetgame()" v-on:click.native="opemMatchdaySelect" class="navigation_icon small"></CalendarIcon>
          <DetailViewIcon v-if="!isBattle() && !isTeamsBetgame() && show && matchdayMatches && matchdayMatches.length > 0" v-on:click.native="switchView" class="navigation_icon small"></DetailViewIcon>
          <DetailViewActiveIcon v-if="!isBattle() && !isTeamsBetgame() && !show && matchdayMatches && matchdayMatches.length > 0" v-on:click.native="switchView" class="navigation_icon small"></DetailViewActiveIcon>
        </template>
      </NavigationBar>
    </keep-alive>
    
    <div v-if="this.hideHint === 0 && isKORound && matches && matches.length > 0" class="betMatchList"
         style="padding: 0px; margin-top: 0px; margin-bottom: 0px;">
      <div style="text-align: center; display: block; border-radius: 10px; border: #ffffff 4px solid; padding: 5px; background-color: white; 
               margin: 16px 16px 0px 16px; max-width: 576px;">
        <div class="dashboard-text" style="padding: 2px;">
          <!-- <p style="color: #5F5D61">{{ $t('rules.place_bet_paragraph_3') }}</p> -->
          <p v-if="resultmode === 3" style="color: #5F5D61">{{ $t('rules.result_mode_title') }}: {{ $t('betgame_settings.result_mode_90m_description') }} <a href="/rules">{{ $t('betgame_settings.betgame_rules') }}</a></p>
          <p v-else-if="resultmode === 2" style="color: #5F5D61">{{ $t('rules.result_mode_title') }}: {{ $t('betgame_settings.result_mode_120m_description') }} <a href="/rules">{{ $t('betgame_settings.betgame_rules') }}</a></p>
          <p v-else-if="resultmode === 1" style="color: #5F5D61">{{ $t('rules.result_mode_title') }}: {{ $t('betgame_settings.result_mode_total_description') }} <a href="/rules">{{ $t('betgame_settings.betgame_rules') }}</a></p>
          <button @click="doHideHint(1)" style="margin-top: 10px;" id="ko_round_bet_hint_button">{{
              $t('hide_hint')
            }}
          </button>
        </div>
      </div>
    </div>

    <div v-if="this.showGenerateBets === 'true' && isEditable && this.selectedBatgameTab === 'matchdayMatches'" class="betgenerator">
      <button @click="randomBets(1)" class="button">
        <img src="@/assets/icons/ic_24_dices.svg" class="button-icon button-icon--left">
        {{ $t('bet.generate_bets') }}
      </button>
    </div>
    
    <div v-if="isKORound && matches && matches.length === 0" class="betMatchList"
         style="padding: 0px; margin-top: 0px; margin-bottom: 0px;">
      <div style="text-align: center; display: block; border-radius: 10px; border: #ffffff 4px solid; padding: 5px; background-color: white; 
               margin: 16px 16px 0px 16px; max-width: 576px;">
        <div class="dashboard-text" style="padding: 2px;">
          <p style="color: #5F5D61">{{ $t('ko_round_matches_not_scheduled') }}</p>
          <router-link :to="{name: 'tournament'}">
            <button style="margin-top: 10px;" id="ko_round_matches_not_scheduled">{{ $t('menu.tournament_groups_schedule') }}</button>
          </router-link>
        </div>
      </div>
    </div>
    
    <div v-if="isBattle()" id="tournament_nav">
      <div v-if="hasTodaysMatches" id="todaysMatches" v-on:click="tabChanged('todaysMatches')" class="tournament_tab" v-bind:class="{ tournament_tab_selected: this.selectedTab === 'todaysMatches'}">{{ $t('bet.tab_todays_matches') }}</div>
      <!-- <div v-if="hasPastMatches" id="lastMatches" v-on:click="tabChanged('lastMatches')" class="tournament_tab" v-bind:class="{ tournament_tab_selected: this.selectedTab === 'lastMatches'}">{{ $t('bet.tab_last_7_days') }}</div> -->
      <!-- <div v-if="hasFutureMatches" id="nextMatches" v-on:click="tabChanged('nextMatches')" class="tournament_tab" v-bind:class="{ tournament_tab_selected: this.selectedTab === 'nextMatches'}">{{ $t('bet.tab_next_7_days') }}</div> -->
      <div v-if="hasPastMatches" id="last10Matches" v-on:click="tabChanged('last10Matches')" class="tournament_tab" v-bind:class="{ tournament_tab_selected: this.selectedTab === 'last10Matches'}">{{ $t('bet.tab_last_10_matches') }}</div>
      <div v-if="hasFutureMatches" id="next10Matches" v-on:click="tabChanged('next10Matches')" class="tournament_tab" v-bind:class="{ tournament_tab_selected: this.selectedTab === 'next10Matches'}">{{ $t('bet.tab_next_10_matches') }}</div>
      <div id="allMatches" v-on:click="tabChanged('allMatches')" class="tournament_tab" v-bind:class="{ tournament_tab_selected: this.selectedTab === 'allMatches'}">{{ $t('bet.tab_all_matches') }}</div>
    </div>
    <div v-else-if="isTeamsBetgame()" id="tournament_nav">
      <div v-if="hasTodaysMatches" id="todaysMatches" v-on:click="competitionTabChanged('todaysMatches')" class="tournament_tab" v-bind:class="{ tournament_tab_selected: this.selectedTab === 'todaysMatches'}">{{ $t('bet.tab_todays_matches') }}</div>
      <div v-for="c in competitions" :id="c.heimspielid" v-on:click="competitionTabChanged(c.heimspielid)" class="tournament_tab" v-bind:class="{ tournament_tab_selected: selectedTab === c.heimspielid }">{{ c.name }}</div>
    </div>
    <div v-else-if="!todaysMatchesSameMatchday" id="tournament_nav">
        <div class="tournament_tab" v-bind:class="{ tournament_tab_selected: this.selectedBatgameTab === 'matchdayMatches' }" v-on:click="betgameTabChanged('matchdayMatches')">{{ matchdayText }}</div>
        <div class="tournament_tab" v-bind:class="{ tournament_tab_badge: pendingTipsCount > 0, tournament_tab_selected: this.selectedBatgameTab === 'todaysMatches' }" v-on:click="betgameTabChanged('todaysMatches')">{{ $t('bet.tab_todays_matches') }}</div>
    </div>
      
    <div class="bet_content">
      <keep-alive>
        <component v-bind:is="currentTabComponent" :matchday="matchday" :selectedTab="selectedTab" :selectedBatgameTab="selectedBatgameTab"
                   @opemMatchdaySelect="opemMatchdaySelect"
                   class="tab"></component>
      </keep-alive>
    </div>

    <div class="navigation_bottom" v-if="!isBattle() && !isTeamsBetgame() && (show || !show)">
      <div class="navigation_bottom--left" v-on:click="decrement">
        <img src="../assets/icons/ic_16_back.svg">
      </div>
      <div v-if="isTournament()" class="navigation_bottom--center" variant="outline-primary" v-on:click="opemMatchdaySelect">
        {{ $t('select_round') }}
      </div>
      <div v-else class="navigation_bottom--center" variant="outline-primary" v-on:click="opemMatchdaySelect">
        {{ $t('select_matchday') }}
      </div>
      <div class="navigation_bottom--right" v-on:click="increment">
        <img src="../assets/icons/ic_16_next.svg">
      </div>
    </div>
  
    <!-- Matchday Select Code -->
    <modal name="matchday-select" id="matchday-select" :scrollable="true" width="100%" height="auto">
      <div class="menu_header">
          <div @click="$modal.hide('matchday-select')" class="burger_menu">
              <span></span>
              <span></span>
          </div>
          <span v-if="isTournament()" class="menu_header-title">{{ $t('select_round') }}</span>
          <span v-else class="menu_header-title">{{ $t('select_matchday') }}</span>
      </div>

      <div v-if="isTournament()" class="matchday-select-list matchday-select-list--longtitles">
        <button v-for="md in matchdayCount" :key="md" 
                v-bind:class="{ selected_matchday: md === currentMatchday, current_matchday: md === $store.state.currentMatchdayBet && currentMatchday !== $store.state.currentMatchdayBet }" 
                v-on:click="matchdaySelected(md)">{{ matchdayTextGlobal(md, competition) }}
        </button>
      </div>
      <div v-else class="matchday-select-list">
        <button v-for="md in matchdayCount" :key="md" :style="{'color': getMatchdayColor(md)}"
                v-bind:class="{ selected_matchday: md === currentMatchday, current_matchday: md === $store.state.currentMatchdayBet && currentMatchday !== $store.state.currentMatchdayBet }"
                v-on:click="matchdaySelected(md)">{{ md }}</button>
      </div>
      
    </modal>
    
  </div>
</template>

<script>
import BetMatchList from '@/components/BetMatchList.vue'
import BetMatchSlider from '@/components/BetMatchSlider.vue'
import BetTotoSlider from '@/components/BetTotoSlider.vue'
import { isNumeric, isNotNull, isToday } from '../vuex/helpers'
import NavigationBar from './../components/NavigationBar'
import DetailViewIcon from './../components/icons/DetailViewIcon'
import DetailViewActiveIcon from './../components/icons/DetailViewActiveIcon'
import CalendarIcon from './../components/icons/CalendarIcon'
import Vue from 'vue'
import { isMobileOnly } from 'mobile-device-detect';
import { BETMODE_TOTO, TYPE_BATTLE_ID, TYPE_TEAMS_ID } from '@/main'
import moment from 'moment/moment'

export default {
  data() {
    return {
      show: true,
      matchday: 1,
      componentKey: 0,
      hideHint: 0,
      maxMatches: 10,
      selectedTab: 'allMatches',
      selectedBatgameTab: 'matchdayMatches',
      showBadge: false, 
      parsedMatches: []
    }
  },
  components: {
    BetMatchList, BetMatchSlider, NavigationBar, DetailViewIcon, DetailViewActiveIcon, CalendarIcon, BetTotoSlider
  },
  beforeCreate() {
    Vue.$log.debug('bet beforeCreate')
  },
  created () {
    Vue.$log.debug('bet created ' + this.isBattle() + ' - ' + this.isTeamsBetgame())

    if(this.isBattle() || this.isTeamsBetgame()) {
      if(this.$store.state.matches == null || this.$store.state.matches.length === 0) {
        this.$store.dispatch('fetchSchedule', this.$store.state.leagueId).then(() => {
          this.parsedMatches = JSON.parse(JSON.stringify(this.$store.state.matches))
          if(this.isTeamsBetgame() && this.competitions.length > 0 && this.selectedTab === 'allMatches') {
            this.selectedTab = this.competitions[0].heimspielid
          }
          this.componentKey += 1
        })
      } else {
        this.parsedMatches = JSON.parse(JSON.stringify(this.$store.state.matches))
      }
    }
    
    if((this.isBattle() || this.isTeamsBetgame()) && this.$store.state.competitions.length === 0) {
      this.$store.dispatch('fetchCompetitions', '')
    }
    if(this.isTeamsBetgame() && (!this.$store.state.betgameTeams || this.$store.state.betgameTeams.length === 0)) {
      this.$store.dispatch('fetchBetgameTeams', this.$store.state.betgameId).then(() => {
        this.componentKey += 1
      })
    }
    
    if(this.isTeamsBetgame() && this.hasTodaysMatches) {
      this.selectedTab = 'todaysMatches'
    } else if(this.isTeamsBetgame() && this.competitions.length > 0) {
      this.selectedTab = this.competitions[0].heimspielid
    } else if(this.isBattle() && (this.$store.state.battleBetSelectedTab === null || this.$store.state.battleBetSelectedTab === '')) {
      if(this.hasTodaysMatches) {
        this.selectedTab = 'todaysMatches'
      } else {
        this.selectedTab = this.matches.length >= this.maxMatches ? (this.hasFutureMatches ? 'next10Matches' : 'last10Matches') : 'allMatches' 
      }
      this.$store.dispatch('setBattleBetSelectedTab', this.selectedTab)
    } else if (this.isBattle()) {
      if(this.hasTodaysMatches && this.$store.state.battleBetSelectedTab === 'allMatches') {
        this.selectedTab = 'todaysMatches'
        this.$store.dispatch('setBattleBetSelectedTab', this.selectedTab)
      } else {
        this.selectedTab = this.$store.state.battleBetSelectedTab
      }
    }
    
    const md = this.$route.params.matchday
    if(isNumeric(md) && parseInt(md) > 0 && parseInt(md) < 100) {
      this.matchday = parseInt(md)
    } else {
      this.matchday = this.$store.state.currentMatchdayBet
    }

    this.hideHint = parseInt(this.getBGValue('spmhh')) || 0
    
    const lastFetchSchedule = this.getBGValue('spmlfs')
    const duration2 = (new Date() - new Date(lastFetchSchedule)) / 1000
    if(duration2 > this.$store.getters.getCachingDuration || this.$store.state.matches == null || this.$store.state.matches.length === 0) {
      this.$store.dispatch('fetchSchedule', this.$store.state.leagueId)
    }
  
    if (this.$store.state.teams.length === 0) {
      this.$store.dispatch('fetchTeams', this.$store.state.betgameId)
    }
    
    if(this.$store.state.bets.length === 0 && this.$store.state.user) {
      this.$store.dispatch('fetchBets', this.$store.state.user.id).then(response => {
        this.showBadge = !this.betForAllTodaysMatches
      })
    } else {
      this.showBadge = !this.betForAllTodaysMatches
    }
  },
  mounted() {
    Vue.$log.debug('bet mounted ' + this.isBattle() + ' - ' + this.isTeamsBetgame())
  },
  computed: {
    competitions() {
      var r = []
      for(var i = 0; i < this.$store.state.betgameTeamsCompetitions.length; i++) {
        const item = this.$store.state.betgameTeamsCompetitions[i]
        const c = this.$store.state.competitions.filter(c => c.id === item)
        if(c.length > 0) {
          r.push(c[0])
        }
      }
      return r.sort((a, b) => a.id - b.id)
    },
    pendingTipsCount() {
      if(this.$store.state.missing_bets !== undefined && this.$store.state.missing_bets !== null) {
        const mb = this.$store.state.missing_bets
        var result = 0
        for (var i = 0; i < mb.length; i++) {
          const item = mb[i]
          if (item.matchcount > item.betcount) {
            result += item.matchcount - item.betcount
          }
        }
        return result
      }
      return 0
    },
    todaysMatchesSameMatchday() {
      return !this.$store.getters.todaysMatchesMultipleMatchdays
    },
    hasTodaysMatches() {
      const filtered = this.matches.filter(t => this.parseMatchdate(t.matchdate).isSame(moment(), 'days'))
      return filtered.length > 0
    },
    hasPastMatches() {
      const filtered = this.matches.filter(t => this.parseMatchdate(t.matchdate).isBefore(moment(), 'days'))
      return filtered.length > 0
    },
    hasFutureMatches() {
      const filtered = this.matches.filter(t => this.parseMatchdate(t.matchdate).isAfter(moment(), 'days'))
      return filtered.length > 0
    },
    resultmode() {
      return this.$store.state.betgame.result_mode || RESULT_MODE_TOTAL
    },
    matches () {
      if(this.isBattle()) {
        //const m = JSON.parse(JSON.stringify(this.$store.state.matches))
        const t = this.parsedMatches.sort(function (a, b) {
          return Date.parse(a.matchdate) - Date.parse(b.matchdate)
        })
        return t
      } else if(this.isTeamsBetgame()) {
        //const m = JSON.parse(JSON.stringify(this.$store.state.matches))
        const t = this.parsedMatches.sort(function (a, b) {
          return Date.parse(a.matchdate) - Date.parse(b.matchdate)
        })
        return t
      } else {
        if(this.selectedBatgameTab === 'matchdayMatches') {
          const m = this.$store.getters.getMatchesByMatchday(this.matchday).sort(function (a, b) {
            return Date.parse(a.matchdate) - Date.parse(b.matchdate)
          })
          return m  
        } else {
          return this.$store.getters.getTodaysMatches
        }
      }
    },
    isKORound() {
      if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 10700) {
        if(this.matchday > 3) {
          return true
        }
      } else if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 36) {
        if(this.matchday > 3) {
          return true
        }
      } else if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 87) {
        if(this.matchday > 3) {
          return true
        }
      } else if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 1379) {
        if(this.matchday > 3) {
          return true
        }
      } else if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 1180) {
        if(this.matchday > 3) {
          return true
        }
      } else if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 33) {
        return true
      } else if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 139) {
        if(this.matchday > 3) {
          return true
        }
      } else if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 1110) {
        if(this.matchday > 3) {
          return true
        }
      } else if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 19) {
        if(this.matchday > 8) {
          return true
        }
      } else if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 132) {
        if(this.matchday > 8) {
          return true
        }
      } else if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 6395) {
        if(this.matchday > 6) {
          return true
        }
      } else if(this.$store.state.betgame && this.$store.state.betgame.type && parseInt(this.$store.state.betgame.type) === TYPE_BATTLE_ID && this.$store.state.matches) {
        const battleMatches = JSON.parse(JSON.stringify(this.$store.state.matches))
        const temp = battleMatches.filter(m => this.isCompetitionTournament(parseInt(m.fk_competition)))
        return temp && temp.length > 0
      } else if(this.$store.state.betgame && this.$store.state.betgame.type && parseInt(this.$store.state.betgame.type) === TYPE_TEAMS_ID && this.$store.state.matches) {
        const teamsMatches = JSON.parse(JSON.stringify(this.$store.state.matches))
        const temp = teamsMatches.filter(m => this.isCompetitionTournament(parseInt(m.fk_competition)))
        return temp && temp.length > 0
      }
      return false
    },
    matchdayMatches() {
      if(this.isBattle()) {
        const m = JSON.parse(JSON.stringify(this.$store.state.matches))
        const t = m.sort(function (a, b) {
          return Date.parse(a.matchdate) - Date.parse(b.matchdate)
        })
        return t
      } else if(this.isTeamsBetgame()) {
        const m = JSON.parse(JSON.stringify(this.$store.state.matches))
        const t = m.sort(function (a, b) {
          return Date.parse(a.matchdate) - Date.parse(b.matchdate)
        })
        return t
      } else {
        if (!this.matchday || this.matchday === 0) {
          return []
        }
        return this.$store.getters.getMatchesByMatchday(this.matchday)
      }
    },
    matchdayTextTitle() {
      if (this.selectedBatgameTab === 'todaysMatches') {
        return this.$i18n.t('bet.tab_todays_matches')
      }
      return this.matchdayText
    },
    matchdayText() {
      if(this.$store.state.betgame && this.$store.state.betgame.competition &&
          (this.$store.state.betgame.competition.id === 19 || this.$store.state.betgame.competition.id === 132)) {
        const nextMatchday = this.matchday
        if(nextMatchday === 9) {
          return this.$i18n.t('ko_round_playoffs_first_legs')
        } else if(nextMatchday === 10) {
          return this.$i18n.t('ko_round_playoffs_second_legs')
        } else if(nextMatchday === 11) {
          return this.$i18n.t('ko_round_of_16_first_legs')
        } else if(nextMatchday === 12) {
          return this.$i18n.t('ko_round_of_16_second_legs')
        } else if(nextMatchday === 13) {
          return this.$i18n.t('ko_round_quarter_finals_first_legs')
        } else if(nextMatchday === 14) {
          return this.$i18n.t('ko_round_quarter_finals_second_legs')
        } else if(nextMatchday === 15) {
          return this.$i18n.t('ko_round_semi_finals_first_legs')
        } else if(nextMatchday === 16) {
          return this.$i18n.t('ko_round_semi_finals_second_legs')
        } else if(nextMatchday === 17) {
          return this.$i18n.t('ko_round_final')
        }
      } else if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 6395) {
        const nextMatchday = this.matchday
        if(nextMatchday === 7) {
          return this.$i18n.t('ko_round_playoffs_first_legs')
        } else if(nextMatchday === 8) {
          return this.$i18n.t('ko_round_playoffs_second_legs')
        } else if(nextMatchday === 9) {
          return this.$i18n.t('ko_round_of_16_first_legs')
        } else if(nextMatchday === 10) {
          return this.$i18n.t('ko_round_of_16_second_legs')
        } else if(nextMatchday === 11) {
          return this.$i18n.t('ko_round_quarter_finals_first_legs')
        } else if(nextMatchday === 12) {
          return this.$i18n.t('ko_round_quarter_finals_second_legs')
        } else if(nextMatchday === 13) {
          return this.$i18n.t('ko_round_semi_finals_first_legs')
        } else if(nextMatchday === 14) {
          return this.$i18n.t('ko_round_semi_finals_second_legs')
        } else if(nextMatchday === 15) {
          return this.$i18n.t('ko_round_final')
        }
      } else if(this.$store.state.betgame && this.$store.state.betgame.competition && 
          (this.$store.state.betgame.competition.id === 10700 || this.$store.state.betgame.competition.id === 36)) {
        const nextMatchday = this.matchday
        if(nextMatchday === 1) {
          return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
        } else if(nextMatchday === 2) {
          return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
        } else if(nextMatchday === 3) {
          return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
        } else if(nextMatchday === 4) {
          return this.$i18n.t('em_ko_round_of_16')
        } else if(nextMatchday === 5) {
          return this.$i18n.t('em_ko_round_quarter_finals')
        } else if(nextMatchday === 6) {
          return this.$i18n.t('em_ko_round_semi_finals')
        } else if(nextMatchday === 7) {
          return this.$i18n.t('ko_round_final')
        }
      } else if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 533) {
        const nextMatchday = this.matchday
        if(nextMatchday === 1) {
          return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
        } else if(nextMatchday === 2) {
          return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
        } else if(nextMatchday === 3) {
          return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
        } else if(nextMatchday === 8) {
          return this.$i18n.t('ko_round_fifth_place')
        } else if(nextMatchday === 9) {
          return this.$i18n.t('em_ko_round_semi_finals')
        } else if(nextMatchday === 10) {
          return this.$i18n.t('ko_round_third_place')
        } else if(nextMatchday === 11) {
          return this.$i18n.t('ko_round_final')
        }
      } else if(this.$store.state.betgame && this.$store.state.betgame.competition &&
          (this.$store.state.betgame.competition.id === 139 || this.$store.state.betgame.competition.id === 1110)) {
        const nextMatchday = this.matchday
        if(nextMatchday === 1) {
          return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
        } else if(nextMatchday === 2) {
          return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
        } else if(nextMatchday === 3) {
          return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
        } else if(nextMatchday === 4) {
          return this.$i18n.t('em_ko_round_of_16')
        } else if(nextMatchday === 5) {
          return this.$i18n.t('em_ko_round_quarter_finals')
        } else if(nextMatchday === 6) {
          return this.$i18n.t('em_ko_round_semi_finals')
        } else if(nextMatchday === 7) {
          return this.$i18n.t('ko_round_third_place_short')
        } else if(nextMatchday === 8) {
          return this.$i18n.t('ko_round_final')
        } 
      } else if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 1180) {
        const nextMatchday = this.matchday
        if(nextMatchday === 1) {
          return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
        } else if(nextMatchday === 2) {
          return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
        } else if(nextMatchday === 3) {
          return this.$i18n.t('em_first_round_matchday', { matchday: nextMatchday })
        } else if(nextMatchday === 4) {
          return this.$i18n.t('em_ko_round_quarter_finals')
        } else if(nextMatchday === 5) {
          return this.$i18n.t('em_ko_round_semi_finals')
        } else if(nextMatchday === 6) {
          return this.$i18n.t('ko_round_final')
        }
      } else if(this.$store.state.betgame && this.$store.state.betgame.competition && this.$store.state.betgame.competition.id === 999) {
        const nextMatchday = this.matchday
        if(nextMatchday === 9) {
          return this.$i18n.t('em_ko_round_of_16')
        } else if(nextMatchday === 10) {
          return this.$i18n.t('em_ko_round_quarter_finals')
        } else if(nextMatchday === 11) {
          return this.$i18n.t('em_ko_round_semi_finals')
        } else if(nextMatchday === 12) {
          return this.$i18n.t('ko_round_final')
        }
      } else if(this.$store.state.betgame && this.$store.state.betgame.competition &&
          (this.$store.state.betgame.competition.id === 188 || this.$store.state.betgame.competition.id === 189)) {
        const nextMatchday = this.matchday
        if(nextMatchday === 1) {
          return this.$i18n.t('hockey_olympia_first_round_matchday', { matchday: nextMatchday })
        } else if(nextMatchday === 2) {
          return this.$i18n.t('hockey_olympia_first_round_matchday', { matchday: nextMatchday })
        } else if(nextMatchday === 3) {
          return this.$i18n.t('hockey_olympia_first_round_matchday', { matchday: nextMatchday })
        } else if(nextMatchday === 4) {
          return this.$i18n.t('hockey_olympia_first_round_matchday', { matchday: nextMatchday })
        } else if(nextMatchday === 5) {
          return this.$i18n.t('hockey_olympia_first_round_matchday', { matchday: nextMatchday })
        } else if(nextMatchday === 6) {
          return this.$i18n.t('em_ko_round_quarter_finals')
        } else if(nextMatchday === 7) {
          return this.$i18n.t('em_ko_round_semi_finals')
        } else if(nextMatchday === 8) {
          return this.$i18n.t('ko_round_third_place_short')
        } else if(nextMatchday === 9) {
          return this.$i18n.t('ko_round_final')
        }
      }
      if(this.$store.state.tournamentRounds && this.$store.state.tournamentRounds.length > 0) {
        var matchdays = 0
        for (var i = 0; i < this.$store.state.tournamentRounds.length; i++) {
          const round = this.$store.state.tournamentRounds[i]
          matchdays += round.matchdaycount
          if(matchdays >= this.matchday) {
            if(this.$store.state.betgame.competition.id === 19 || this.$store.state.betgame.competition.id === 132 || 
                this.$store.state.betgame.competition.id === 6395) {
              return round.name + ', ' + this.$i18n.t('matchday', { matchday: this.matchday })
            } else if(this.$store.state.betgame.competition.id === 4228) {
              if(this.matchday <= 6) {
                return round.name + ', ' + this.$i18n.t('matchday', { matchday: this.matchday })
              } else {
                return round.name
              }
            } else {
              return round.name 
            }
          }
        }
      }
      return this.$i18n.t('matchday', { matchday: this.matchday })
    },
    switchViewAvailable() {
      if(this.$store.state.betgame.competition !== undefined && this.$store.state.betgame.competition !== null) {
        return this.$store.state.betgame.competition.type === 'LEAGUE'
      }
      return false
    },
    currentTabComponent: function() {
      if (this.show) {
        return 'BetMatchList'
      } else {
        if(isMobileOnly && this.isSPMUser() && this.isBattle() && this.$store.state.betgame.bet_mode === 2) {
          return 'BetTotoSlider'          
        } else {
          return 'BetMatchSlider'
        }
      }
    },
    matchdayCount() {
      return this.$store.state.matchdayCount
    },
    currentMatchday() {
      return this.matchday
    },
    competition() {
      return this.$store.state.betgame.competition.id
    },
    betForAllTodaysMatches() {
      const bets = this.$store.state.bets
      var result = true
      if (bets.length > 0) {
        const matchdayMatches = this.$store.state.matches
        if (matchdayMatches.length > 0) {
          for (var i = 0; i < matchdayMatches.length; i++) {
            const match = matchdayMatches[i]
            if(match.status && (match.status.trim() === 'annulliert' || match.status.trim() === 'abgesagt'
              || match.status.trim() === 'verlegt' || match.status.trim() === 'Wertung')) {
              continue
            }
            const matchdate = this.parseMatchdateToLocalTimeZone(match.matchdate)
            if(matchdate && isToday(new Date(matchdate))) {
              const betMatches = bets.filter(betMatch => betMatch.fk_match === match.id)
              if (betMatches.length === 0) {
                return false
              } else {
                if(isNumeric(betMatches[0].goalshome) && isNumeric(betMatches[0].goalsguest)) {
                  result = true
                } else {
                  return false
                }
              }
            }
          }
        }
      }
      return result
    },
    isEditable() {
      var result = false
      const matches = this.$store.getters.getMatchesByMatchday(this.matchday)
      for(var i = 0; i < matches.length; i++) {
        const match = matches[i]
        if (match.status && (match.status.trim() === 'annulliert' || match.status.trim() === 'abgesagt' ||
            match.status.trim() === 'verlegt' || match.status.trim() === 'Wertung')) {
          continue
        }
        const editable = (this.isFuture(match.matchdate) && !this.isNumeric(match.goalshome) && !this.isNumeric(match.goalsguest) &&
            !this.isNumeric(match.livegoalshome) && !this.isNumeric(match.livegoalsguest))
        if (editable) {
          result = true
          break
        }
      }
      return result
    },
    isBetmodeToto() {
      return this.$store.state.betgame.bet_mode === BETMODE_TOTO
    },
    showGenerateBets() {
      return this.getBGValue('spmgenbets') ? this.getBGValue('spmgenbets') : 'true'
    }
  },
  methods: {
    getMatches() {
      if(this.isBattle()) {
        //const m = JSON.parse(JSON.stringify(this.$store.state.matches))
        if(this.selectedTab === 'nextMatches') {
          const d = moment().add(7, 'days')
          const filtered = this.parsedMatches.filter(t => this.parseMatchdate(t.matchdate).isSameOrBefore(d, 'days') && this.parseMatchdate(t.matchdate).isAfter(moment(), 'days'))
          if(filtered.length > 0) {
            const sorted = filtered.sort(function (a, b) {
              return  a.matchdate === b.matchdate ? a.id - b.id : Date.parse(a.matchdate) - Date.parse(b.matchdate)
            })
            return sorted
          } else {
            const sorted = this.parsedMatches.sort(function (a, b) {
              return a.matchdate === b.matchdate ? a.id - b.id : Date.parse(a.matchdate) - Date.parse(b.matchdate)
            })
            return sorted.slice(0, 10)
          }
        } else if(this.selectedTab === 'lastMatches') {
          const d = moment().subtract(7, 'days')
          const filtered = this.parsedMatches.filter(t => this.parseMatchdate(t.matchdate).isSameOrAfter(d, 'days') && this.parseMatchdate(t.matchdate).isBefore(moment(), 'days'))
          if(filtered.length > 0) {
            const sorted = filtered.sort(function (a, b) {
              return a.matchdate === b.matchdate ? a.id - b.id : Date.parse(a.matchdate) - Date.parse(b.matchdate)
            })
            return sorted
          } else {
            const sorted = this.parsedMatches.sort(function (a, b) {
              return a.matchdate === b.matchdate ? a.id - b.id : Date.parse(a.matchdate) - Date.parse(b.matchdate)
            })
            return sorted.slice(Math.max(sorted.length - 10, 0))
          }
        } else if(this.selectedTab === 'next10Matches') {
          const filtered = this.parsedMatches.filter(t => this.parseMatchdate(t.matchdate).isAfter(moment(), 'days'))
          if(filtered.length > 0) {
            const sorted = filtered.sort(function (a, b) {
              return a.matchdate === b.matchdate ? a.id - b.id : Date.parse(a.matchdate) - Date.parse(b.matchdate)
            })
            return sorted.slice(0, 10)
          } else {
            const sorted = this.parsedMatches.sort(function (a, b) {
              return a.matchdate === b.matchdate ? a.id - b.id : Date.parse(a.matchdate) - Date.parse(b.matchdate)
            })
            return sorted.slice(0, 10)
          }
        } else if(this.selectedTab === 'last10Matches') {
          const filtered = this.parsedMatches.filter(t => this.parseMatchdate(t.matchdate).isBefore(moment(), 'days'))
          if(filtered.length > 0) {
            const sorted = filtered.sort(function (a, b) {
              return a.matchdate === b.matchdate ? a.id - b.id : Date.parse(a.matchdate) - Date.parse(b.matchdate)
            })
            return sorted.slice(Math.max(sorted.length - 10, 0))
          } else {
            const sorted = this.parsedMatches.sort(function (a, b) {
              return a.matchdate === b.matchdate ? a.id - b.id : Date.parse(a.matchdate) - Date.parse(b.matchdate)
            })
            return sorted.slice(Math.max(sorted.length - 10, 0))
          }
        } else if(this.selectedTab === 'todaysMatches') {
          const filtered = this.parsedMatches.filter(t => this.parseMatchdate(t.matchdate).isSame(moment(), 'days'))
          const sorted = filtered.sort(function (a, b) {
            return a.matchdate === b.matchdate ? a.id - b.id : Date.parse(a.matchdate) - Date.parse(b.matchdate)
          })
          return sorted
        }else {
          const t = this.parsedMatches.sort(function (a, b) {
            return a.matchdate === b.matchdate ? a.id - b.id : Date.parse(a.matchdate) - Date.parse(b.matchdate)
          })
          return t
        }
      } else if(this.isTeamsBetgame()) {
        const allmatches = this.parsedMatches //JSON.parse(JSON.stringify(this.$store.state.matches))
        if(this.selectedTab === 'todaysMatches') {
          const filtered = allmatches.filter(t => this.parseMatchdate(t.matchdate).isSame(moment(), 'days'))
          const sorted = filtered.sort(function (a, b) {
            return a.matchdate === b.matchdate ? a.id - b.id : Date.parse(a.matchdate) - Date.parse(b.matchdate)
          })
          return sorted
        } else if (parseInt(this.selectedTab) > 0) {
          const selectedcompetition = this.$store.state.competitions.filter(t => t.heimspielid === this.selectedTab)
          const filteredmatches = allmatches.filter(t => t.fk_competition === selectedcompetition[0].id)
          const sortedmatches = filteredmatches.sort(function (a, b) {
            return a.matchdate === b.matchdate ? a.id - b.id : Date.parse(a.matchdate) - Date.parse(b.matchdate)
          })
          return sortedmatches
        }
        return []
      } else {
        if (this.selectedBatgameTab === 'matchdayMatches') {
          const m = this.$store.getters.getMatchesByMatchday(this.matchday).sort(function (a, b) {
            if (a.status && (b.status.trim() === 'annulliert' || a.status.trim() === 'abgesagt' ||
                a.status.trim() === 'verlegt' || a.status.trim() === 'Wertung')) {
              return 1
            } else if (b.status && (b.status.trim() === 'annulliert' || b.status.trim() === 'abgesagt' ||
                b.status.trim() === 'verlegt' || b.status.trim() === 'Wertung')) {
              return -1
            }
            return a.matchdate === b.matchdate ? a.id - b.id : Date.parse(a.matchdate) - Date.parse(b.matchdate)
          })
          return m
        } else {
          const m = this.$store.getters.getTodaysMatches.sort(function (a, b) {
            if (a.status && (b.status.trim() === 'annulliert' || a.status.trim() === 'abgesagt' ||
                a.status.trim() === 'verlegt' || a.status.trim() === 'Wertung')) {
              return 1
            } else if (b.status && (b.status.trim() === 'annulliert' || b.status.trim() === 'abgesagt' ||
                b.status.trim() === 'verlegt' || b.status.trim() === 'Wertung')) {
              return -1
            }
            if(Date.parse(a.matchdate) === Date.parse(b.matchdate)) {
              return a.matchday - b.matchday
            } else {
              return a.matchdate === b.matchdate ? a.id - b.id : Date.parse(a.matchdate) - Date.parse(b.matchdate)
            }
          })
          return m
        }
      }
    },
    randomBets(val) {
      this.$gtag.event('RandomBets', {
        'event_category': 'Bet',
        'event_label': this.$store.state.leagueId ? '' + this.$store.state.leagueId : '',
        'dimension1': this.$store.state.betgame && this.$store.state.betgame.name ? this.$store.state.betgame.name : 'betgame not set',
        'dimension2': this.$store.state.user && this.$store.state.user.source ? this.$store.state.user.source : 'user source not set',
        'dimension3': this.$store.state.betgame && this.$store.state.betgame.id ? this.$store.state.betgame.id : 'betgame id not set',
        'dimension5': this.$store.state.betgame && this.$store.state.betgame.type ? this.$store.state.betgame.type : 'betgame type not set'
      })
      const matches = this.getMatches()
      for(var i = 0; i < matches.length; i++) {
        const match = matches[i]
        if(match.status && (match.status.trim() === 'annulliert' || match.status.trim() === 'abgesagt' ||
            match.status.trim() === 'verlegt' || match.status.trim() === 'Wertung')) {
          continue
        }
        const editable = (this.isFuture(match.matchdate) && !this.isNumeric(match.goalshome) && !this.isNumeric(match.goalsguest) &&
            !this.isNumeric(match.livegoalshome) && !this.isNumeric(match.livegoalsguest))
        if(!editable) {
          continue
        }
        //const bets = this.$store.state.bets.filter(bet => bet.fk_match === match.id && bet.fk_betgame === this.$store.state.betgame.id && 
        //  bet.fk_user === this.$store.state.user.id)
        const matchbet = this.$store.getters.getMatchBet(match.id)
        var rand_home = 5
        var rand_guest = 4
        if(matchbet.length === 1) {
          rand_guest = Math.floor(parseFloat(matchbet[0].bet_1) + 1)
          rand_home = Math.floor(parseFloat(matchbet[0].bet_2) + 1)
        }
        //if(bets.length > 0 && this.isNumeric(bets[0].goalshome) && this.isNumeric(bets[0].goalsguest)) {
          //rand_home = bets[0].goalshome + 1 < 10 ? bets[0].goalshome + 1 : 9
          //rand_guest = bets[0].goalsguest + 1 < 10 ? bets[0].goalsguest + 1 : 9
        //}
        if(editable) {
          var goalsHome = Math.floor(Math.random() * rand_home) % 7
          var goalsGuest = Math.floor(Math.random() * rand_guest) % 7
          while (goalsHome + goalsGuest > 8) {
            goalsHome = Math.floor(Math.random() * rand_home) % 7
            goalsGuest = Math.floor(Math.random() * rand_guest) % 7
          }
          //const thn = this.getTeamName(match.fk_team_home, match.team_home)
          //const tgn = this.getTeamName(match.fk_team_guest, match.team_guest)
          if(this.isBetmodeToto) {
            if(goalsHome > goalsGuest) {
              this.saveBet(match.id, 1, 1)
            } else if(goalsHome < goalsGuest) {
              this.saveBet(match.id, 2, 2)
            } else if (goalsHome === goalsGuest) {
              this.saveBet(match.id, 0, 0)
            }
          } else {
            this.saveBet(match.id, goalsHome, goalsGuest)            
          }
        }
      }
      this.$store.dispatch('fetchSchedule', this.$store.state.leagueId).then(() => {
        this.parsedMatches = JSON.parse(JSON.stringify(this.$store.state.matches))
        this.componentKey += 1
      })
    },
    saveBet(match_id, home, guest) {
      const betdata = {
        'fk_user': this.$store.state.user.id,
        'fk_match': match_id,
        'goalshome': parseInt(home),
        'goalsguest': parseInt(guest),
        'fk_betgame': parseInt(this.$store.state.betgameId)
      }
      this.$store.dispatch('updateBet', betdata).then(response => {
        if(response.status >= 200 && response.status <= 204) {
          const eh = document.getElementById(match_id + '_h')
          if(eh) {
            eh.value = home
          }
          const eg = document.getElementById(match_id + '_g')
          if(eg) {
            eg.value = guest
          }
          this.$store.dispatch('setLastBetSaveDate', moment().format('YYYY-MM-DD HH:mm:ss')).then(() => {
            this.componentKey += 1
          })
        } else {
          Vue.$log.debug('Bet.vue saveBet error ' + response.status)
        }
      }, error => {
        Vue.$log.debug('Bet.vue saveBet error ' + error)
      })
    },
    competitionTabChanged(tabid) {
      Vue.$log.debug('Bet.vue competitionTabChanged ' + tabid)
      if(this.selectedTab !== tabid) {
        this.selectedTab = tabid
        this.$store.dispatch('setBattleBetSelectedTab', this.selectedTab)
        const tabbar = document.getElementById('tournament_nav')
        const tab = document.getElementById(tabid)
        tabbar.scrollLeft = tab.offsetLeft - (tabbar.offsetWidth/4 + tab.offsetWidth/2)
      }
    },
    betgameTabChanged(tabid) {
      Vue.$log.debug('Bet.vue betgameTabChanged ' + tabid)
      if(this.selectedBatgameTab !== tabid) {
        this.selectedBatgameTab = tabid
        this.showBadge = !this.betForAllTodaysMatches
        this.componentKey += 1
      }
    },
    tabChanged(tabid) {
      Vue.$log.debug('Bet.vue tabChanged ' + tabid)
      if(this.selectedTab !== tabid) {
        this.selectedTab = tabid
        this.$store.dispatch('setBattleBetSelectedTab', this.selectedTab)
        const tabbar = document.getElementById('tournament_nav')
        const tab = document.getElementById(tabid)
        tabbar.scrollLeft = tab.offsetLeft - (tabbar.offsetWidth/4 + tab.offsetWidth/2)
      }
    },
    isCompetitionTournament(competition) {
      const tournments = [19,33,36,87,132,139,188,189,533,541,999,1110,1180,1379,4228,6395,10700]
      return tournments.includes(competition)
    },
    doHideHint(val) {
      this.hideHint = val
      this.setBGValue('spmhh', val)
    },
    switchView() {
      this.show = !this.show
      if(this.show) {
        this.$gtag.pageview({
          page_title: 'bet',
          page_path: '/bet',
          page_location: window.location.href
        })
      } else {
        this.$gtag.pageview({
          page_title: 'betslider',
          page_path: '/betslider',
          page_location: window.location.href + 'slider'
        })
      }
      document.getElementById("app").scrollIntoView()
      this.componentKey += 1
    },
    opemMatchdaySelect() {
      document.getElementById("app").scrollIntoView()
      this.$modal.show('matchday-select');
    },
    matchdaySelected (m) {
      Vue.$log.debug('bet matchdaySelected ' + m)
      this.matchday = m
      this.$modal.hide('matchday-select');
      document.getElementById("app").scrollIntoView()
      this.componentKey += 1
    },
    increment () {
      if (this.matchday < this.$store.state.matchdayCount) {
        this.matchday++
      } else {
        this.matchday = 1
      }
    },
    decrement () {
      if (this.matchday > 1) {
        this.matchday--
      } else {
        this.matchday = this.$store.state.matchdayCount
      }
    },
    getMatchdayColor(matchday) {
      const matches = this.$store.getters.getMatchesByMatchday(matchday)
      var betCount = 0
      var finishedCount = 0
      for(var i = 0; i < matches.length; i++) {
        const match = matches[i]
        if(this.isNumeric(match.goalshome) && this.isNumeric(match.goalsguest)) {
          finishedCount += 1
        }
        const bets = this.$store.state.bets.filter(bet => bet.fk_match === match.id && bet.fk_betgame === this.$store.state.betgame.id && 
          bet.fk_user === this.$store.state.user.id)
        if(bets.length > 0 && this.isNumeric(bets[0].goalshome) && this.isNumeric(bets[0].goalsguest)) {
          betCount += 1
        }
      }
      if(matches.length === finishedCount) {
        return '#000000'
      } else if(betCount === 0) {
        return '#ff0000'
      } else if(betCount < matches.length) {
        return '#ffff00'
      } else if(betCount === matches.length){
        return '#00ff00'
      }
      return '#000000'
    },
  }
}
</script>

<style lang="scss" scoped>

  .bet {
    padding: 0;
    display: block;
  }
  
  .betMatchList {
      display: block;
      padding: 0 16px 16px;
      max-width: 576px;
      margin: 24px auto 0;

      @media screen and (min-width: 768px) {
        padding: 26px 16px 50px;
        margin: 24px auto 0;
      }
  }

  .navigation_bottom {
    margin-left: 0;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  /* Matchday Select Code */
  .burger_menu {
    width: 64px;
    height: 64px;
    position: relative;
    cursor: pointer;

    span {
        width: 16px;
        height: 2px;
        position: absolute;
        left: 16px;
        top: 31px;
        background: $colorGreyDark;

        &:first-child {
            top: 31px;
            transform: rotate(45deg);
        }

        &:last-child {
            top: 31px;
            transform: rotate(-45deg);
        }
    }
  }
  .menu_header {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      display: flex;
      height: 64px;
      align-items: center;
      background: linear-gradient(to bottom, $colorWhite 65%, rgba($colorWhite, .8) 80%, rgba($colorWhite, 0) 100%);

      &-title {
        font-size: 17px;
        color: $colorGreyDark;
        text-align: center;
        justify-content: center;
        display: flex;
        flex: 1;
      }
  }
  .matchday-select-list {
    padding: 64px 8px 16px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-width: 576px;
    margin: 0px auto;

    button {
      background: rgba($colorGreyDark, .04);
      width: calc(20% - 16px);
      box-shadow: inset 0 0 0 1px rgba(95,93,97,0.08);
      border-radius: 4px;
      color: $colorGreyDark;
      padding: 0;
      display: flex;
      flex: 1 0 calc(20% - 16px);
      flex-grow: 0;
      margin: 8px 8px;
      justify-content: center;
      align-items: center;
      position: relative;

      @media screen and (min-width: 768px) {
        width: calc(16.3% - 16px);
        flex: 1 0 calc(16.3% - 16px);
        flex-grow: 0;
      }

      &:before {
        content: "";
        display: block;
        padding-top: 100%;
      }
    }

    &--longtitles {
      flex-direction: column;

      button {
        width: 100%;
        flex: 1;
        padding: 12px 0;
        margin: 8px 0;

        &:before {
          padding-top: 0;
        }
      }
    }
  }

  .betgenerator {
    max-width: 576px;
    padding: 16px 16px 0;
    margin: 0 auto;

    @media screen and (min-width: 768px) {
      margin-top: 16px;
    }

    button {
      font-size: 14px;
      padding: 12px 19px;

    }
  }
</style>
