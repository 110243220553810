<template>
  <div style="display: flex; flex-direction: column;">
    <div v-if="chatItem.displayDate && chatItem.displayDate !== ''" class="chat__divider--day chat__divider--day--static">
      {{ getDateFormattedChat(chatItem.time) }}
    </div>
    <div v-if="chatItem.showDivider && this.$store.state.unreadCount > 0" id="NEW_MESSAGES_DIVIDER" class="chat__divider--new">
      {{ this.$store.state.unreadCount }} neue Nachrichten
    </div>
    <div class="chat__item" :id="chatItem.id" :class="[ chatItem.sender ? 'chat__item--sender' : '', hasReaction ? 'chat__item--reacted' : '', chatItem.status === -1 ? 'chat__item--deleted' : '', chatItem.showUser ? 'chat__item--messagefirst' : '', chatItem.showAvatar ? 'chat__item--showavatar' : '']">

      <!-- Avatar -->
      <div v-if="chatItem.isbot" class="chat__item__avatar">
        <img src="../../assets/images/teamtip/Teamtip_Logo_300x300.png">
      </div>
      <div v-else-if="!chatItem.sender" class="chat__item__avatar">
        <img v-if="isValidIcon(getAvatar(chatItem.userid))" v-bind:src="buildAvatarPath(getAvatar(chatItem.userid), 300)">
      </div>

        <!-- Content -->
        <div class="chat__item__content" style="user-select: none;">

         	  <!-- Content: Image (Erstmal nicht) -->
            <!--<img class="chat__item__image" src="../assets/whitelabel/header-bg-mobile.png">--->

            <!-- Content: Ticker -->
            <!--<div class="chat__item__ticker">
              <div class="chat__item__ticker__match">
                <img src="../../assets/jerseys/bundesliga1/72/jersey_leverkusen.png" alt="">
                B04
                <div class="chat__item__ticker__match__result">
                  2:1
                </div>
                BOC
                <img src="../../assets/jerseys/bundesliga1/72/jersey_bochum.png" alt="">
              </div>
              <div class="chat__item__ticker__text">
                <img src="../../assets/icons/ic_liveticker_tor.svg">
                <div class="chat__item__ticker__text__content">
                  <div class="chat__item__ticker__text__title">
                    Tor für Leverkusen! (2:1)
                  </div>
                  <div class="chat__item__ticker__text__subtitle">
                    Granit Xhaka · 89. Minute
                  </div>
                </div>
              </div>
            </div>-->

            <!-- Content: Text -->
          	<div class="chat__item__message">

              <div v-if="chatItem.showUser">
                <!-- Title -->
                <div v-if="chatItem.sender" class="chat__item__message__title">
                  <div class="chat__item__message__title__username">
                    {{ $t('chat.me') }} ({{ chatItem.username }})
                  </div>
                  <span>{{ chatItem.time | formatTimeChat }}</span>
                  <div v-if="chatItem.status !== -1" class="chat__item__message__title__actions">
                  </div>
                </div>
                <div v-else class="chat__item__message__title">
                  <div class="chat__item__message__title__username">
                    {{ chatItem.username }}
                  </div>
                  <span>{{ chatItem.time | formatTimeChat }}</span>
                  <div v-if="chatItem.status !== -1" class="chat__item__message__title__actions">
                  </div>
                </div>
              </div>
              
              <!-- Optional: Answer -->
              <ChatAnswer v-if="chatItem.in_response_to_message && chatItem.in_response_to_message._id" :key="chatItem.in_response_to_message._id" 
                          :username="chatItem.in_response_to_message.username" :text="chatItem.in_response_to_message.text" 
                          :chatItem="chatItem.in_response_to_message" @answerClicked="answerClicked"></ChatAnswer>
              
             	<!-- Nachricht -->
              <p @touchstart="touchstart" @touchend="touchend" v-if="chatItem.text && !chatItem.text.includes('http')">{{ chatItem.text }}</p>
              <p @touchstart="touchstart" @touchend="touchend" v-else-if="chatItem.text && chatItem.text.includes('http')" v-html="formattedChatItemText"></p>

              <!-- Bild -->
              <img @touchstart="touchstart(embed)" @touchend="touchend(embed)" v-if="chatItem.embeds && imageEmbeds.length > 0" v-for="(embed, index) in imageEmbeds" :key="index" 
                   :src="fileURL(embed.uri)" class="chat__item__message__image">

              <div v-if="chatItem.embeds && fileEmbeds.length > 0" v-for="(embed, index) in fileEmbeds" :key="index"
                   class="chat__item__message__file">
                <div class="chat__item__message__file__icon"></div>
                <div class="chat__item__message__file__text">
                  <div class="chat__item__message__file__text__label">
                    {{ embed.filename }}
                  </div>
                  <div class="chat__item__message__file__text__sublabel">
                    {{ getFileinfo(embed) }}
                  </div>
                </div>
              </div>
              
              <!-- Optional: Reactions -->
             	<div v-if="chatItem && chatItem.reactions && chatItem.reactions.length > 0 && chatItem.status !== -1" class="chat__item__message__reactions">

              		<!-- Reaction Item -->
              		<div v-if="reactionsSortedDistinct.length < 3" v-for="r in reactionsSorted" v-on:click.stop="reactionClicked(r)" class="chat__item__message__reactions__item">
                     	<span>{{ r.text }}</span>{{ r.count }}
                  </div>
                  <div v-if="reactionsSortedDistinct.length >= 3" class="chat__item__message__reactions__item">
                    <span v-for="r in reactionsSortedDistinct" v-on:click.stop="reactionClicked(r)">{{ r.text }}</span>{{ reactionsSorted.length }}
                  </div>
                
                </div>
          	</div>
        </div>
      </div>
      <div v-if="fullscreenImageURL && fullscreenImageURL !== ''" @click="hideFullscreen" class="fullscreen-image">
        <img :src="fullscreenImageURL" alt="Full screen Image" />
      </div>
    </div>
</template>

<script>
  import ChatAnswer from '../../components/chat/ChatAnswer'

	export default {
    name: 'ChatItem',
    components: { ChatAnswer },
    props: {
      chatItem: Object,
    },
  	data () {
			return {
        touchduration: 600,
        timer: null,
        start: null,
        fullscreenImageURL: ''
      }
  	},
  	beforeCreate () {
  	},
  	methods: {
        hideFullscreen() {
          this.fullscreenImageURL = ''
        },
        touchstart(embed) {
          if (!this.timer) {
            const onlongtouch = () => {
              if (this.timer !== null) {
                clearTimeout(this.timer)
                this.timer = null
                if(this.chatItem.embeds.length > 0) {
                  this.$emit('itemClicked', {embed: embed})
                } else {
                  this.$emit('itemClicked', {})
                }
              }
            }
            this.start = Date.now()
            this.timer = setTimeout(onlongtouch, this.touchduration)
          }
        },
        touchend(embed) {
          const millis = Date.now() - this.start
          if(this.timer) {
            clearTimeout(this.timer)
            this.timer = null
            if(this.chatItem.embeds.length > 0) {
              this.fullscreenImageURL = this.fileURL(embed.uri)
            }
          } else {
          }
        },
        getFileinfo(embed) {
          var filesize = embed.size ? (embed.size / 1024).toFixed(2) : 0
          if(filesize > 1024) {
            filesize = (filesize / 1024).toFixed(2) + ' MB'
          } else {
            filesize = filesize + ' KB'
          }
          if(embed.mimetype && embed.mimetype.endsWith('pdf')) {
            return "PDF" + ' - ' + filesize
          } else if(embed.mimetype && embed.mimetype.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
            return "Excel" + ' - ' + filesize
          } else if(embed.mimetype && embed.mimetype.startsWith('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
            return "Word" + ' - ' + filesize
          } else if (embed.mimetype && embed.mimetype.startsWith('application/vnd.openxmlformats-officedocument.presentationml.presentation')) {
            return "Powerpoint" + ' - ' + filesize
          }
          return embed.mimetype + ' - ' + filesize
        },
        answerClicked() {
          this.$emit('answerClicked', { id: this.chatItem.in_response_to_message._id })
        },
        reactionClicked(reaction) {
          this.$emit('reactionClicked', { reaction: reaction })
        },
    		itemClicked(event) {
          if(this.chatItem.embeds && this.imageEmbeds.length > 0) {
            if(this.imageEmbeds.length === 1) {
            }
          } else if(this.chatItem.embeds && this.fileEmbeds.length > 0) {
            if(this.fileEmbeds.length === 1) {
              window.open(this.fileURL(this.fileEmbeds[0].uri), '_blank')
            }
          } else if(event && event.srcElement && event.srcElement.tagName && event.srcElement.tagName.toUpperCase() === 'A' && 
              event.srcElement.href && event.srcElement.href !== '') {
            window.open(event.srcElement.href, event.srcElement.target)
          } else {
            this.$emit('itemClicked', {})
          }
    		},
        includeCount(list, item) {
          var count = 0
          for (let i = 0; i < list.length; i++) {
            if(list[i].text === item) {
              count++
            }
          }
          return count
        },
        fileURL(file) {
          return 'https://chat.teamtip.net' + file
        },
        convertLinksToAnchors(text, sender) {
          return text.replace(/(https?:\/\/[^\s]+)/g, (url) => {
            if(sender) {
              if(url.includes('https://teamtip.net') || url.includes('http://teamtip.net')) {
                return `<a href="${url}" style="color: white;" onclick="return false;" target="_self">${url}</a>`;
              } else {
                return `<a href="${url}" style="color: white;" onclick="return false;" target="_blank">${url}</a>`;
              }
            } else {
              if(url.includes('https://teamtip.net') || url.includes('http://teamtip.net')) {
                return `<a href="${url}" onclick="return false;" target="_self">${url}</a>`;
              } else {
                return `<a href="${url}" onclick="return false;" target="_blank">${url}</a>`;
              }
            }
          });
        }
  	},
  	computed: {
      formattedChatItemText() {
        const text = this.chatItem.text
        const textnew = this.convertLinksToAnchors(text, this.chatItem.sender)
        //textnew = textnew.replace('https://teamtip.net', '')
        //textnew = textnew.replace('http://teamtip.net', '')
        return textnew
      },
      formattedChatItemText1() {
        const text = this.chatItem.text
        if(text.includes('http://') || text.includes('https://')) {
          if(this.chatItem.sender) {
            var href = text.match(/https?:\/\/[^\s]+/)[0]
            var target = '_blank'
            if (href.includes('https://teamtip.net')) {
              href = href.replace('https://teamtip.net', '')
              target = '_self'
            } else if (href.includes('http://teamtip.net')) {
              href = href.replace('http://teamtip.net', '')
              target = '_self'
            }
            return text.replace(/(https?:\/\/[^\s]+)/g, '<a style="color: white" href="' + href + '" onclick="return false;" target="' + target + '">$1</a>')
          } else {
            var href = text.match(/https?:\/\/[^\s]+/)[0]
            var target = '_blank'
            if (href.includes('https://teamtip.net')) {
              href = href.replace('https://teamtip.net', '')
              target = '_self'
            } else if (href.includes('http://teamtip.net')) {
              href = href.replace('http://teamtip.net', '')
              target = '_self'
            }
            return text.replace(/(https?:\/\/[^\s]+)/g, '<a href="' + href + '" onclick="return false;" target="' + target + '">$1</a>')
          }
        }
        return text
      },
      imageEmbeds() {
        return this.chatItem.embeds.filter((embed) => {
          return embed.mimetype.startsWith('image/')
        })
      },
      fileEmbeds() {
        return this.chatItem.embeds.filter((embed) => {
          return !embed.mimetype.startsWith('image/')
        })
      },
      hasReaction() {
        return this.chatItem.reactions && this.chatItem.reactions.length > 0
      },
      reactionsSortedDistinct() {
        const sorted = []
        const temp = this.chatItem.reactions
        for (let i = 0; i < temp.length; i++) {
          if(this.includeCount(sorted, temp[i].text) === 0) {
            sorted.push({'text': temp[i].text})
          }
        }
        return sorted
      },
      reactionsSorted() {
        const sorted = []
        const temp = this.chatItem.reactions
        for (let i = 0; i < temp.length; i++) {
          if(this.includeCount(sorted, temp[i].text) > 0) {
            sorted.filter((item) => {
              if(item.text === temp[i].text) {
                item.count++
              }
            })
          } else {
            sorted.push({'text': temp[i].text, 'count': 1})
          }
        }
        return sorted
      }
    }
	}
</script>

<style lang="scss">
  .chat__item {
      display: flex;
      align-items: flex-end;
      margin-top: -8px;
      margin-bottom: 12px;
      max-width: calc(100% - 48px);
      width: auto;

      /* Styling for Chat Item, which has been sent from active user */
      &--sender {
        flex-direction: row-reverse;
        margin-left: auto;
        max-width: calc(100% - 80px);
      }

      &--reacted {
        margin-bottom: 24px !important;
      }

      &--messagefirst {
        margin-top: 0;
        margin-bottom: 12px;
      }

      &__avatar {
        visibility: hidden;
        position: relative;
        flex-shrink: 0;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 8px;
        overflow: hidden;
        background: rgba($colorGreyDark, .08);

        .chat__item--showavatar & {
          visibility: visible;
        }

        &:before {
          position: absolute;
          z-index: -1;
          top: 8px;
          left: 8px;
          width: 16px;
          height: 16px;
          content: url('../../assets/chat/ic_16_user.svg');
          opacity: .48;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }


      }

      &__content {
        display: flex;
        flex-direction: column;
        min-width: 160px;

        > div, img {
          &:not(:last-child) {
            margin-bottom: 4px;
            border-radius: 8px 8px 4px 4px;

            ~ div { 
              border-radius: 4px 4px 8px 4px;
            }
          }
        }
      }

      &__image {
        border-radius: 8px 8px 8px 4px;
        width: 100%;
        max-height: 250px;
        object-fit: contain;
        background: rgba($colorGreyDark, .12);
        transition: all .2s ease-in-out;

        &:hover {
          cursor: pointer;
          background: rgba($colorGreyDark, .2);
        }
      }

      &__message {
        background: $colorWhite;
        border-radius: 8px 8px 8px 2px;
        padding: 13px 16px 10px;

        .chat__item--sender & {
          background: var(--color-primary);
          border-radius: 8px 8px 2px 8px;
        }

        .chat__item--deleted & {
          background: rgba($colorGreyDark, .12);
        }

        &__title {
          display: flex;
          margin-bottom: 3px;
          color: $colorGreyDark;

          .chat__item--sender & {
            color: $colorWhite;
          }

          .chat__item--deleted & {
            color: rgba($colorGreyDark, .64);
          }

          &__username {
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-right: 4px;
          }

          span {
            font-size: 11px;
            line-height: 20px;
            font-weight: 400;
            opacity: .48;
            margin-left: auto;
          }

          &__actions {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 16px;
            margin: -18px -16px -16px -12px;
            cursor: pointer;

            &:hover {
             span {
              background: $colorGreyDark;

              .chat__item--sender & {
               background: $colorWhite;
              }
             }
            }

            span {
             width: 2.5px;
             height: 2.5px;
             border-radius: 50%;
             background: rgba($colorGreyDark, .32);
             margin: 0 .75px;
          opacity: 1;
             transition: all .2s ease-in-out;

             .chat__item--sender & {
              background: rgba($colorWhite, .48);
        }
            }
          }
        }

        p {
          font-size: 16px;
          line-height: 22px;
          font-weight: 400;
          color: $colorGreyDark;
          word-break: break-word;
          white-space: pre-line;
          
          .chat__item--sender & {
            color: $colorWhite;
          }

          .chat__item--deleted & {
            color: rgba($colorGreyDark, .64);
            font-style: italic;
          }
        }

        small {
          text-align: left;
          justify-content: flex-start;
          padding: 0 0 2px;
          margin-top: -4px;
        }

        &__image {
          min-width: calc(100% + 24px);
          max-width: 100%;
          max-height: 300px;
          object-fit: contain;
          margin: 5px -12px -10px;
          border-radius: 4px;

          &:first-child {
            margin-top: -9px;
          }

          + .chat__item__message__reactions {
            margin-top: -16px;
          }
        }

        &__file {
          display: flex;
          align-items: center;
          background: rgba(var(--color-primary-rgb), .08);
          border-radius: 4px;
          padding: 8px 16px 8px 8px;
          margin: 8px -8px -2px;
          cursor: pointer;
          transition: all .2s ease-in-out;

          &:first-child {
            margin-top: -5px;
          }

          &:hover {
            background: rgba(var(--color-primary-rgb), .12);
          }

          .chat__item--sender & {
            background: rgba($colorWhite, .24);

            &:hover {
              background: rgba($colorWhite, .32);
            }
          }

          &__icon {
            width: 40px;
            height: 40px;
            padding: 10px;
            box-sizing: border-box;
            content: url('../../assets/chat/ic_20_file_primary.svg');
            background: $colorWhite;
            border-radius: 4px;
            box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);
            margin-right: 12px;
          }

          &__text {
            display: flex;
            flex-direction: column;
            overflow: hidden;

            &__label {
              font-size: 14px;
              line-height: 20px;
              color: $colorGreyDark;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              margin-bottom: 2px;

              .chat__item--sender & {
                color: $colorWhite
              }
            }

            &__sublabel {
              font-size: 11px;
              line-height: 14px;
              color: rgba($colorGreyDark, .6);
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;

              .chat__item--sender & {
                color: rgba($colorWhite, .6);
              }
            }
          }
        }
        
        &__reactions {
          margin: 8px 0 -22px;

          &__item {
            display: inline-flex;
            align-items: center;
            background: $colorWhite;
            box-shadow: inset 0 0 0 1px rgba($colorGreyDark, .12), 0 4px 16px rgba($colorGreyDark, 0);
            font-size: 11px;
            line-height: 0;
            font-weight: 500;
            color: $colorGreyDark;
            padding: 13px 12px 11px 6px;
            border-radius: 12px;
            transition: all .2s ease-in-out;

            &:not(:last-child) {
              margin-right: 4px;
            }

            &:hover {
              box-shadow: inset 0 0 0 1px rgba($colorGreyDark, .24), 0 4px 16px rgba($colorGreyDark, .04);
              transform: translateY(-2px);
              cursor: pointer;
            }

            span {
              font-size: 14px;
              margin-right: 6px;

              + span {
                margin-left: -6px;
              }
            }
          }
        }

        .chat__item--scrolledto & {
          animation: scrolledtoanimation 2.5s forwards;
        }

        @keyframes scrolledtoanimation {
          0% {
            box-shadow: 0 0 0 1px rgba($colorPrimary, .48);
            transform: scale(1);
          }
          5% {
            box-shadow: 0 0 0 1px rgba($colorPrimary, .48);
            transform: scale(1);
          }
          15% {
            box-shadow: 0 0 0 1px rgba($colorPrimary, .48);
            transform: scale(1.05);
          }
          25% {
            box-shadow: 0 0 0 1px rgba($colorPrimary, .48);
            transform: scale(1);
          }
          50% {
            box-shadow: 0 0 0 1px rgba($colorPrimary, .48);
            transform: scale(1);
          }
          100% {
            box-shadow: 0 0 0 1px rgba($colorPrimary, 0);
            transform: scale(1);
          }
        }
      }

      &__ticker {

        &__match {
          display: flex;
          justify-content: center;
          align-items: center;
          background: $colorWhite;
          border-radius: 8px 8px 0 0;
          padding: 12px 16px;
          font-size: 14px;
          color: $colorGreyDark;
          margin-bottom: 1px;

          img {
            width: 32px;
            height: 32px;
            border-radius: 0 !important;
            margin: 0 8px 0 0 !important;

            &:last-child {
              margin: 0 0 0 8px !important;
            }
          }

          &__result {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            margin: auto;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 2px;

            /*span {
              font-size: 10px;
              color: $colorLive;
              letter-spacing: 0;
            }*/
          }
        }

        &__text {
          display: flex;
          align-items: center;
          background: $colorWhite;
          border-radius: 0 0 8px 4px;
          padding: 12px 16px;

          img {
            margin-right: 12px;
          }

          &__content {
            display: flex;
            flex-direction: column;
          }

          &__title {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 500;
            color: $colorGreyDark;

            img {
              margin-right: 6px;
            }
          }

          &__subtitle {
            font-size: 11px;
            color: rgba($colorGreyDark, .64);
            margin-top: 2px;
          }
        }
      }
    }
    .fullscreen-image {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 9999;
    }
  
    .fullscreen-image img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
</style>
